// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { ReactIcon, ReactIconProps } from './ReactIcon';

export const NpBluesky = (props: ReactIconProps) => {
  return (
    <ReactIcon viewBox="0 0 32 32" w="32px" h="32px" {...props}>
      <path d="M31.9978 16.0006C31.9978 24.0816 26.0078 30.7624 18.2265 31.8465C17.4989 31.9474 16.7545 32.0001 15.9989 32.0001C15.1267 32.0001 14.2702 31.9306 13.4361 31.796C5.81839 30.5695 0 23.9639 0 16.0006C0 7.16399 7.16368 6.10352e-05 16 6.10352e-05C24.8363 6.10352e-05 32 7.16399 32 16.0006H31.9978Z" />
      <path
        d="M15.6366 15.0791C14.7973 13.4604 12.521 10.437 10.402 8.94882C8.37213 7.52328 7.59586 7.7687 7.09233 7.99846C6.50488 8.26477 6.39998 9.16291 6.39998 9.6903C6.39998 10.2177 6.68846 14.0243 6.88253 14.6562C7.51193 16.7553 9.74109 17.4655 11.7972 17.2357C11.9021 17.22 12.007 17.2044 12.1171 17.1939C12.0122 17.2096 11.9021 17.2253 11.7972 17.2357C8.78649 17.6796 6.10625 18.7709 9.62046 22.6611C13.4808 26.6401 14.9127 21.81 15.647 19.3557C16.3814 21.8047 17.2258 26.4625 21.6002 22.6611C24.8889 19.3557 22.5024 17.6796 19.4917 17.2357C19.3868 17.2253 19.2766 17.2096 19.1717 17.1939C19.2819 17.2096 19.3868 17.22 19.4917 17.2357C21.5478 17.4655 23.7822 16.7553 24.4063 14.6562C24.5951 14.0191 24.8889 10.2177 24.8889 9.6903C24.8889 9.16291 24.784 8.26477 24.1965 7.99846C23.6877 7.7687 22.9167 7.52328 20.8869 8.94882C18.7521 10.4422 16.4758 13.4604 15.6366 15.0791Z"
        fill="white"
      />
    </ReactIcon>
  );
};
