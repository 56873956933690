// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { UniformX } from './UniformX';

export interface FooterBaseProps extends FlexProps {}

export const FooterBase = forwardRef<FooterBaseProps, 'div'>(
  ({ children, ...props }, ref) => {
    return (
      <Flex ref={ref} as="footer" bg="npGray.700" color="white" {...props}>
        <UniformX>{children}</UniformX>
      </Flex>
    );
  },
);
