// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { NextSeo, NextSeoProps } from 'next-seo';
import seo from '~/common/config/seo';

export type SEOProps = Pick<
  NextSeoProps,
  | 'title'
  | 'description'
  | 'openGraph'
  | 'canonical'
  | 'additionalMetaTags'
  | 'titleTemplate'
>;

export const SEO = ({
  title,
  description,
  openGraph,
  canonical,
  additionalMetaTags,
  titleTemplate,
}: SEOProps) => (
  <NextSeo
    title={title}
    canonical={canonical}
    description={description}
    titleTemplate={titleTemplate ?? seo.titleTemplate}
    additionalMetaTags={additionalMetaTags}
    openGraph={{
      ...seo.openGraph,
      title,
      description,
      ...openGraph,
    }}
    twitter={{
      ...seo.twitter,
    }}
  />
);
