// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  ButtonGroup,
  ButtonProps,
  forwardRef,
  Image,
  ImageProps,
  Link,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { FC } from 'react';

interface LogoButtonProps extends ButtonProps {
  imageProps?: ImageProps;
}

export const LogoButton = forwardRef<LogoButtonProps, 'div'>(
  ({ imageProps, ...props }, ref) => {
    const buttonSize = useBreakpointValue({
      base: '/Brandmark.png',
      md: '/lockup-horiz.svg',
    });

    return (
      <Link variant="unstyled" href="/">
        <Button ref={ref} variant="unstyled" {...props}>
          <Image
            alt="Go to home"
            h={7}
            src={buttonSize}
            {...imageProps}
          ></Image>
        </Button>
      </Link>
    );
  },
);

export const PricingButton = forwardRef<ButtonProps, 'div'>((props, ref) => {
  return (
    <NextLink href="/pricing">
      <Button ref={ref} variant="npGhost" aria-label="Pricing" {...props}>
        Pricing
      </Button>
    </NextLink>
  );
});

export const LoginButton = forwardRef<ButtonProps, 'div'>((props, ref) => {
  return (
    <NextLink href="/login">
      <Button ref={ref} variant="npGhost" aria-label="Login" {...props}>
        Login
      </Button>
    </NextLink>
  );
});

export const SignUpButton = forwardRef<ButtonProps, 'div'>((props, ref) => {
  return (
    <NextLink href="/signup">
      <Button ref={ref} variant="npPrimary" aria-label="Sign up" {...props}>
        Sign up
      </Button>
    </NextLink>
  );
});

export const HeaderContent: FC = () => {
  return (
    <>
      <LogoButton />
      <Spacer />
      <ButtonGroup>
        <PricingButton />
        <LoginButton />
        <SignUpButton />
      </ButtonGroup>
    </>
  );
};
