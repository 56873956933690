// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { ReactIcon, ReactIconProps } from './ReactIcon';

export const NpFacebook = (props: ReactIconProps) => {
  return (
    <ReactIcon viewBox="0 0 32 32" w="32px" h="32px" {...props}>
      <path d="M31.9978 16.0006C31.9978 24.0816 26.0078 30.7624 18.2265 31.8465C17.4989 31.9474 16.7545 32.0001 15.9989 32.0001C15.1267 32.0001 14.2702 31.9306 13.4361 31.796C5.81839 30.5695 0 23.9639 0 16.0006C0 7.16399 7.16368 6.10352e-05 16 6.10352e-05C24.8363 6.10352e-05 32 7.16399 32 16.0006H31.9978Z" />
      <path
        d="M18.2265 12.8481V16.3337H22.5381L21.8554 21.0289H18.2265V31.8465C17.4989 31.9474 16.7545 32.0001 15.9989 32.0001C15.1267 32.0001 14.2702 31.9306 13.4361 31.7961V21.0289H9.45966V16.3337H13.4361V12.0689C13.4361 9.4231 15.5807 7.27728 18.2276 7.27728V7.27953C18.2354 7.27953 18.2422 7.27728 18.25 7.27728H22.5393V11.338H19.7366C18.9036 11.338 18.2276 12.014 18.2276 12.847L18.2265 12.8481Z"
        fill="white"
      />
    </ReactIcon>
  );
};
