// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import Link from 'next/link';
import { NpBluesky } from '~/components/Icons/NpBluesky';
import { NpDiscord } from '~/components/Icons/NpDiscord';
import { NpFacebook } from '~/components/Icons/NpFacebook';
import { NpMastodon } from '~/components/Icons/NpMastodon';
import { NpThreads } from '~/components/Icons/NpThreads';

export const SocialStack = () => {
  return (
    <>
      <Link href="https://www.facebook.com/niphtio/" target="_blank">
        <NpFacebook />
      </Link>
      <Link href="https://bsky.app/profile/nipht.io" target="_blank">
        <NpBluesky />
      </Link>
      <Link href="https://discord.gg/kFnjSrXwKN" target="_blank">
        <NpDiscord />
      </Link>
      <Link href="https://www.threads.net/@niphtio" target="_blank">
        <NpThreads />
      </Link>
      <Link href="https://mastodon.social/@niphtio" target="_blank">
        <NpMastodon />
      </Link>
    </>
  );
};
