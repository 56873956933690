// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { UniformX } from './UniformX';

interface HeaderBaseProps extends FlexProps {}

export const HeaderBase = forwardRef<HeaderBaseProps, 'div'>(
  ({ children, ...props }, ref) => {
    return (
      <Flex
        ref={ref}
        as="header"
        position="sticky"
        top={0}
        bg="npBrandedBg"
        zIndex={3}
        py={2}
        {...props}
      >
        <UniformX display="flex">{children}</UniformX>
      </Flex>
    );
  },
);
