// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { ReactIcon, ReactIconProps } from './ReactIcon';

export const NpDiscord = (props: ReactIconProps) => {
  return (
    <ReactIcon viewBox="0 0 32 32" w="32px" h="32px" {...props}>
      <path d="M16.0364 6.10352e-05H15.9636C7.14712 6.10352e-05 0 7.14719 0 15.9636V16.0365C0 24.8529 7.14712 32.0001 15.9636 32.0001H16.0364C24.8529 32.0001 32 24.8529 32 16.0365V15.9636C32 7.14719 24.8529 6.10352e-05 16.0364 6.10352e-05Z" />
      <path
        d="M22.7231 9.95449C21.4938 9.39503 20.1766 8.98309 18.7986 8.74743C18.7734 8.7428 18.7481 8.75393 18.7359 8.77712C18.5666 9.07587 18.3786 9.46647 18.2476 9.77264C16.7657 9.55275 15.2914 9.55275 13.8394 9.77264C13.7075 9.45905 13.5129 9.07587 13.3427 8.77712C13.3296 8.75486 13.3043 8.74372 13.28 8.74743C11.9029 8.98309 10.5848 9.39503 9.35549 9.95449C9.3452 9.95913 9.33584 9.96655 9.32929 9.97675C6.82959 13.6805 6.14477 17.2933 6.48062 20.8616C6.48249 20.8792 6.49185 20.8959 6.50588 20.9061C8.1552 22.1076 9.75309 22.8368 11.321 23.3202C11.3463 23.3276 11.3725 23.3184 11.3884 23.2979C11.7588 22.796 12.09 22.2662 12.3735 21.7086C12.3903 21.6762 12.3735 21.6372 12.3398 21.6242C11.815 21.4266 11.3163 21.1863 10.8355 20.9135C10.7971 20.8913 10.7943 20.8374 10.8289 20.8115C10.93 20.7363 11.031 20.6584 11.1274 20.5795C11.1451 20.5647 11.1694 20.5619 11.19 20.5712C14.3455 22 17.7621 22 20.8801 20.5712C20.9007 20.561 20.925 20.5647 20.9428 20.5786C21.0392 20.6575 21.1402 20.7363 21.2422 20.8115C21.2768 20.8374 21.2749 20.8913 21.2366 20.9135C20.7567 21.1919 20.2571 21.4275 19.7313 21.6242C19.6967 21.6372 19.6817 21.6762 19.6986 21.7096C19.9886 22.2653 20.3188 22.7951 20.6827 23.2979C20.6977 23.3193 20.7248 23.3286 20.7501 23.3211C22.3255 22.8378 23.9234 22.1085 25.5727 20.907C25.5867 20.8968 25.5961 20.8801 25.598 20.8634C26.0002 16.7385 24.9244 13.1554 22.7474 9.97861C22.7418 9.96841 22.7334 9.96006 22.7222 9.95542L22.7231 9.95449ZM12.8431 18.6887C11.8926 18.6887 11.1105 17.824 11.1105 16.7617C11.1105 15.6994 11.8786 14.8347 12.8431 14.8347C13.8076 14.8347 14.5916 15.7077 14.5757 16.7617C14.5757 17.824 13.8076 18.6887 12.8431 18.6887ZM19.2505 18.6887C18.3 18.6887 17.5179 17.824 17.5179 16.7617C17.5179 15.6994 18.2859 14.8347 19.2505 14.8347C20.215 14.8347 20.9989 15.7077 20.983 16.7617C20.983 17.824 20.2234 18.6887 19.2505 18.6887Z"
        fill="white"
      />
    </ReactIcon>
  );
};
