// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
import { ResponsiveValue, ThemeTypings } from '@chakra-ui/styled-system';
import { isArray } from 'lodash';

type Sizes =
  | ThemeTypings['components']['Button']['sizes']
  | ThemeTypings['components']['Button']['sizes'][];

const getBoxSize = (
  size?: ResponsiveValue<ThemeTypings['components']['Button']['sizes']>,
) => {
  switch (size) {
    case 'xs':
      return 4;
    case 'sm':
      return 5;
    case 'md':
    default:
      return 6;
  }
};

export interface ReactIconProps extends IconProps {
  size?: Sizes;
}

export const ReactIcon = forwardRef<ReactIconProps, 'svg'>(
  ({ size, ...props }, ref) => {
    const boxSize = isArray(size) ? size.map(getBoxSize) : getBoxSize(size);
    return <Icon ref={ref} boxSize={boxSize} {...props} />;
  },
);
