// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { ReactIcon, ReactIconProps } from './ReactIcon';

export const NpMastodon = (props: ReactIconProps) => {
  return (
    <ReactIcon viewBox="0 0 32 32" w="32px" h="32px" {...props}>
      <path d="M16.0364 6.10352e-05H15.9636C7.14712 6.10352e-05 0 7.14719 0 15.9636V16.0365C0 24.8529 7.14712 32.0001 15.9636 32.0001H16.0364C24.8529 32.0001 32 24.8529 32 16.0365V15.9636C32 7.14719 24.8529 6.10352e-05 16.0364 6.10352e-05Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4793 20.6613C21.6763 20.3954 23.5892 19.0229 23.8296 17.7688C24.2084 15.7933 24.1772 12.948 24.1772 12.948C24.1772 9.09142 21.6851 7.96101 21.6851 7.96101C20.4285 7.37588 18.2709 7.12982 16.0292 7.11124H15.9741C13.7325 7.12982 11.5762 7.37588 10.3196 7.96101C10.3196 7.96101 7.82742 9.09142 7.82742 12.948C7.82742 13.1818 7.82623 13.4277 7.82499 13.6837C7.82154 14.3946 7.81772 15.1834 7.838 16.0067C7.9285 19.7782 8.51997 23.4952 11.9592 24.4182C13.545 24.8437 14.9065 24.9328 16.003 24.8717C17.9914 24.7599 19.1077 24.1522 19.1077 24.1522L19.0421 22.6894C19.0421 22.6894 17.6211 23.1437 16.0252 23.0883C14.4441 23.0334 12.7749 22.9155 12.5192 20.9472C12.4956 20.7744 12.4838 20.5895 12.4838 20.3954C12.4838 20.3954 14.0359 20.78 16.003 20.8714C17.2057 20.9273 18.3337 20.7999 19.4793 20.6613ZM21.2376 17.9168V13.2471C21.2376 12.2928 20.9979 11.5344 20.5166 10.9733C20.0201 10.4122 19.3699 10.1246 18.563 10.1246C17.6291 10.1246 16.922 10.4884 16.4546 11.2163L16 11.9888L15.5455 11.2163C15.078 10.4884 14.3709 10.1246 13.4372 10.1246C12.6301 10.1246 11.98 10.4122 11.4836 10.9733C11.0021 11.5344 10.7625 12.2928 10.7625 13.2471V17.9168H12.5871V13.3844C12.5871 12.429 12.9836 11.944 13.7767 11.944C14.6536 11.944 15.0931 12.5193 15.0931 13.6568V16.1376H16.907V13.6568C16.907 12.5193 17.3465 11.944 18.2234 11.944C19.0164 11.944 19.4129 12.429 19.4129 13.3844V17.9168H21.2376Z"
        fill="white"
      />
    </ReactIcon>
  );
};
