// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef, Spacer } from '@chakra-ui/react';
import { FooterBase } from '~/containers/layouts/FooterBase';
import { SocialStack } from '../SocialStack';
import {
  ContactUsLink,
  GuidesLink,
  PricingLink,
  PrivacyLink,
  TermsLink,
} from './FooterContent';

export const Footer = forwardRef<FlexProps, 'div'>((props, ref) => {
  return (
    <FooterBase zIndex={100}>
      <Flex
        direction={['column', 'column', 'row']}
        fontWeight="medium"
        fontSize="md"
        py={8}
      >
        <Flex gap={[2, 2, 4]} flexDir={['column', 'column', 'row']}>
          <ContactUsLink />
          <GuidesLink />
          <PrivacyLink />
          <TermsLink />
          <PricingLink />
        </Flex>
        <Spacer mb={4} />
        <Flex gap={3} filter="invert(100%)">
          <SocialStack />
        </Flex>
      </Flex>
    </FooterBase>
  );
});
