// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef } from '@chakra-ui/react';
import NextLink from 'next/link';
import { RiDiscordFill, RiTwitterXFill } from 'react-icons/ri';
import { ReactIcon } from '~/components/Icons/ReactIcon';

type NextLinkProps = Partial<React.ComponentProps<typeof NextLink>>;

export const ContactUsLink = forwardRef<NextLinkProps, 'link'>((props, ref) => {
  return (
    <NextLink ref={ref} href="mailto:info@nipht.io" {...props}>
      Contact us
    </NextLink>
  );
});

export const GuidesLink = forwardRef<NextLinkProps, 'link'>((props, ref) => {
  return (
    <NextLink ref={ref} href="/guides/welcome-to-niphtio" {...props}>
      Guides
    </NextLink>
  );
});

export const PrivacyLink = forwardRef<NextLinkProps, 'link'>((props, ref) => {
  return (
    <NextLink ref={ref} href="/privacy" {...props}>
      Privacy policy
    </NextLink>
  );
});

export const TermsLink = forwardRef<NextLinkProps, 'div'>((props, ref) => {
  return <NextLink href="/terms">Terms</NextLink>;
});

export const CareersLink = forwardRef<NextLinkProps, 'link'>((props, ref) => {
  return (
    <NextLink ref={ref} href="/careers" {...props}>
      Careers
    </NextLink>
  );
});

export const PricingLink = forwardRef<NextLinkProps, 'link'>((props, ref) => {
  return (
    <NextLink ref={ref} href="/pricing" {...props}>
      Pricing
    </NextLink>
  );
});

export const TwitterIconLink = forwardRef<NextLinkProps, 'link'>(
  (props, ref) => {
    return (
      <NextLink href="https://twitter.com/niphtio" target="_blank" {...props}>
        <ReactIcon minW={6} h={6} as={RiTwitterXFill} />
      </NextLink>
    );
  },
);

export const DiscordIconLink = forwardRef<NextLinkProps, 'link'>(
  (props, ref) => {
    return (
      <NextLink href="https://discord.gg/kFnjSrXwKN" target="_blank" {...props}>
        <ReactIcon minW={6} h={6} as={RiDiscordFill} />
      </NextLink>
    );
  },
);
